import { Box, Grid } from '@cheese-fondue/components';
import { Image } from '@cheese-fondue/components/atoms';
import { media } from '@cheese-fondue/helpers';
import { COLORS, SPACING } from '@cheese-fondue/styles';
import { typography } from '@cheese-fondue/styles/typography';
import { FluidObject } from 'gatsby-image';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

export interface TeamMemberModalProps {
  area: string;
  name: string;
  title: string;
  company: string;
  team: string;
  bio: string;
  imageData: FluidObject;
}

const ModalGrid = styled(Grid)`
  width: 100%;
`;

const ModalContentBox = styled(Box)`
  padding: ${SPACING.one}px;

  ${media.medium`
        padding: ${SPACING.two}px;
    `};
`;

const TeamLabel = styled.p`
  ${typography.preSmall};
  color: ${COLORS.teal200};
  margin-top: ${SPACING.one}px;
  ${media.medium`
    margin-top: 0;
  `}
`;

const Headline = styled.h3`
  margin: 0;
  ${media.medium`
    ${typography.h1};
  `}
`;
const Title = styled.p`
  margin-top: ${SPACING.one}px;
  ${media.medium`
    ${typography.pLarge};
    margin-bottom: ${SPACING.one}px;
  `}
`;

const ModalImageBox = styled(Box)`
  /* Gatsby image wrapper */
  > div {
    height: 100%;
    width: 100%;
  }
`;

export const TeamMemberModal = ({
  name,
  title,
  company,
  team,
  bio,
  imageData,
}: TeamMemberModalProps): ReactElement => {
  const formattedBio = bio
    .split(`\n\n`)
    .map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
    .join(``);

  return (
    <ModalGrid tag="section">
      <ModalImageBox cols={[12, 6]}>
        <Image imageData={imageData} altText={name} />
      </ModalImageBox>
      <ModalContentBox cols={[12, 6]}>
        <TeamLabel>{team}</TeamLabel>
        <Headline>{name}</Headline>
        <Title>{title}</Title>
        <div dangerouslySetInnerHTML={{ __html: formattedBio }} />
      </ModalContentBox>
    </ModalGrid>
  );
};
