import { ReactComponent as CreativeDestructionLabLogo } from '@assets/icons/partners/creative-destruction-lab.svg';
import { ReactComponent as GoldenVenturesLogo } from '@assets/icons/partners/golden-ventures.svg';
import { ReactComponent as LererHippeauLogo } from '@assets/icons/partners/lerer-hippeau.svg';
import { ReactComponent as RealLogo } from '@assets/icons/partners/real-ventures.svg';
import { ReactComponent as YCombinatorLogo } from '@assets/icons/partners/y-combinator.svg';
import { ReactComponent as MulticoinLogo } from '@assets/icons/partners/multicoin-capital-logo.svg';
import { ReactComponent as RibbitLogo } from '@assets/icons/partners/ribbit-capital-logo.svg';
import { ReactComponent as ValorLogo } from '@assets/icons/partners/valor-equity-logo.svg';
import { ReactComponent as CumberlandLogo } from '@assets/icons/partners/cumberland-logo.svg';
import { ReactComponent as M13Logo } from '@assets/icons/partners/m13-logo.svg';
import { ReactComponent as LatticeCapitalLogo } from '@assets/icons/partners/lattice-capital.svg';
import { ReactComponent as FJLabsLogo } from '@assets/icons/partners/fj-labs.svg';
import SmallHeaderLayout from '@cheese-fondue/layout/smallHeaderLayout';

import { ComponentWrapper } from '@cheese-fondue/components/componentWrapper/componentWrapper';
import { Box, Grid } from '@cheese-fondue/components/grid';
import { LinkGridList } from '@cheese-fondue/components/linkGridList/linkGridList';
import { ModalContext } from '@cheese-fondue/components/modal/modal-context';
import { TeamList } from '@cheese-fondue/components/team/teamList/teamList';
import {
  TeamMemberModal,
  TeamMemberModalProps,
} from '@cheese-fondue/components/teamMember/teamMemberModal/teamMemberModal';
import { TeamHeaderHero } from '@cheese-fondue/components/teams-header-hero';
import { useImageQuery } from '@cheese-fondue/helpers/queryImage';
import { COLORS } from '@cheese-fondue/styles';
import { ANIMATION_CURVES } from '@cheese-fondue/styles/theme-constants';
import { motion } from 'framer-motion';
import { graphql, PageProps } from 'gatsby';
import { ImageSharpFluid, TeamDataQuery } from 'graphql-types';
import React, { ReactElement, useMemo } from 'react';

export const query = graphql`
  query TeamData {
    companyAreaSums: allTeamYaml {
      group(field: area) {
        area: fieldValue
        count: totalCount
      }
    }
    leadershipTeam: allTeamYaml(filter: { team: { eq: "leadership" } }) {
      edges {
        node {
          id
          area
          bio
          name
          photoUrl
          team
          title
          company
          quote
        }
      }
      areaSums: group(field: area) {
        count: totalCount
        area: fieldValue
      }
    }
    company: allTeamYaml(filter: { team: { eq: "company" } }) {
      edges {
        node {
          id
          area
          bio
          name
          photoUrl
          team
          title
          company
          quote
        }
      }
      areaSums: group(field: area) {
        count: totalCount
        area: fieldValue
      }
    }
    advisorTeam: allTeamYaml(filter: { team: { eq: "advisor" } }) {
      edges {
        node {
          id
          area
          bio
          name
          photoUrl
          team
          title
          quote
        }
      }
      areaSums: group(field: area) {
        count: totalCount
        area: fieldValue
      }
    }
    portraits: allFile(filter: { relativePath: { regex: "/images/team/" } }) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

interface TeamProps extends PageProps {
  data: {
    companyAreaSums: TeamDataQuery['companyAreaSums'];
    leadershipTeam: TeamDataQuery['leadershipTeam'];
    company: TeamDataQuery['company'];
    advisorTeam: TeamDataQuery['advisorTeam'];
    portraits: TeamDataQuery['portraits'];
  };
}

const getImageDataFromPath = (
  images: TeamDataQuery['portraits']['edges'],
  path: string,
): Pick<ImageSharpFluid, 'aspectRatio' | 'src' | 'srcSet' | 'srcWebp' | 'srcSetWebp' | 'sizes'> | null | undefined => {
  for (let i = 0; i < images.length; i++) {
    if (images[i].node.base === path && images[i]?.node?.childImageSharp?.fluid) {
      return images[i].node.childImageSharp?.fluid;
    }
  }

  return null;
};

// Add imageData to employee nodes
const parseTeamData = (
  images: TeamDataQuery['portraits']['edges'],
  edges: TeamDataQuery['leadershipTeam']['edges'],
  orderAlphabetically = false,
): any => {
  const data = edges.map(e => {
    const node = e.node || e;
    return {
      ...node,
      imageData: node.photoUrl ? getImageDataFromPath(images, node.photoUrl) : null,
    };
  });

  return orderAlphabetically
    ? data.sort((a, b) => {
        const aName = a?.name || 'a';
        const bName = b?.name || 'b';
        return aName.localeCompare(bName);
      })
    : data;
};

const teamViewAnimationVariants = {
  visible: {
    opacity: 1,
    display: 'block',
    transition: { ease: ANIMATION_CURVES.bezier, duration: 0.3 },
  },
  hidden: {
    opacity: 0,
    transition: { ease: ANIMATION_CURVES.bezier, duration: 0.2 },
    transitionEnd: {
      display: 'none',
    },
  },
};

const Page = (props: TeamProps): ReactElement => {
  const heroImage = useImageQuery({ src: 'cheese-fondue/orange-marble-1600-1600.jpg' });

  const { leadershipTeam, company, advisorTeam, portraits } = props.data;

  const leadershipData = useMemo(() => parseTeamData(portraits.edges, leadershipTeam.edges, true), [
    leadershipTeam,
    portraits,
  ]);

  const companyData = useMemo(() => parseTeamData(portraits.edges, company.edges, true), [company, portraits]);
  const advisorData = useMemo(() => parseTeamData(portraits.edges, advisorTeam.edges, true), [advisorTeam, portraits]);

  const { handleModal } = React.useContext(ModalContext);
  const toggleTeamMemberModal = (teamMember: TeamMemberModalProps): null =>
    handleModal(<TeamMemberModal {...teamMember} />);

  return (
    <SmallHeaderLayout location={props.location} pageName="Team">
      <Grid>
        <Box cols={[12]}>
          <ComponentWrapper backgroundColorLeft={COLORS.white} backgroundColorRight={COLORS.white}>
            <TeamHeaderHero
              backgroundImage={heroImage!}
              imageAltText="Marbled circle"
              title="Creating unparalleled alignment for investors."
              rightText="We founded Delphia because we believe prosperity should be attainable by anyone. That's why we sought out the smartest, most passionate people we know to create a profitable and principled AI company."
            />
          </ComponentWrapper>
        </Box>
        <Box cols={[12]} style={{ display: 'block', position: 'relative' }}>
          <ComponentWrapper backgroundColorLeft={COLORS.white} backgroundColorRight={COLORS.white}>
            <motion.div animate="visible" variants={teamViewAnimationVariants}>
              <TeamList
                headline="Leadership"
                members={leadershipData}
                teamMemberClickHandler={toggleTeamMemberModal}
              />
              <TeamList headline="Company" members={companyData} teamMemberClickHandler={toggleTeamMemberModal} />
              <TeamList
                headline="Advisors"
                summary={[]}
                members={advisorData}
                teamMemberClickHandler={toggleTeamMemberModal}
              />
            </motion.div>
          </ComponentWrapper>
        </Box>
        <Box cols={[12]}>
          <ComponentWrapper backgroundColorLeft={COLORS.white} backgroundColorRight={COLORS.white}>
            <LinkGridList
              headline="Partners & Investors"
              listItems={[
                {
                  component: <MulticoinLogo />,
                  title: 'Multicoin',
                  url: 'https://multicoin.capital/',
                },
                {
                  component: <RibbitLogo />,
                  title: 'Ribbit',
                  url: 'https://ribbitcap.com/',
                },
                {
                  component: <ValorLogo />,
                  title: 'Valor',
                  url: 'https://www.valorep.com/',
                },
                {
                  component: <CumberlandLogo />,
                  title: 'Cumberland',
                  url: 'https://www.cumberland.io/',
                },
                {
                  component: <M13Logo />,
                  title: 'M13 Ventures',
                  url: 'https://m13.co/',
                },
                {
                  component: <LatticeCapitalLogo />,
                  title: 'Lattice Capital',
                  url: 'https://www.latticecap.co/',
                },
                {
                  component: <FJLabsLogo />,
                  title: 'FJ Labs',
                  url: 'https://fjlabs.com/',
                },
                {
                  component: <CreativeDestructionLabLogo />,
                  title: 'Creative Destruction Lab',
                  url: 'https://www.creativedestructionlab.com/',
                },
                {
                  component: <LererHippeauLogo />,
                  title: 'Lerer-Hippeau',
                  url: 'https://www.lererhippeau.com/',
                },
                {
                  component: <GoldenVenturesLogo />,
                  title: 'Golden Ventures',
                  url: 'https://golden.ventures/',
                },
                {
                  component: <RealLogo />,
                  title: 'Real Ventures',
                  url: 'https://realventures.com/',
                },
                {
                  component: <YCombinatorLogo />,
                  title: 'Y-Combinator',
                  url: 'https://www.ycombinator.com',
                },
              ]}
            />
          </ComponentWrapper>
        </Box>
      </Grid>
    </SmallHeaderLayout>
  );
};

export default Page;
