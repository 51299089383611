import { Box } from '@cheese-fondue/components/grid';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../helpers';
import { COLORS, FONTS, SPACING } from '../../../styles';

interface TeamMemberProps {
  readonly contained?: boolean;
  linkText?: string;
}

export const StyledTeamMember = styled.div`
  position: relative;
  :hover {
    .overlay,
    .text {
      opacity: 1;
      ::after {
        opacity: 1;
      }
    }
  }
`;

export const ImageOuter = styled(Box)``;

export const OverLay = styled.div`
  position: relative;
  :after {
    position: absolute;
    background: linear-gradient(180deg, transparent 0%, ${COLORS.black} 110%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 200ms;
    z-index: 0;
    content: ' ';
  }
`;

export const ContainedText = styled.div`
  position: absolute;
  color: ${COLORS.white};
  bottom: 0;
  opacity: 0;
`;

export const MemberName = styled.h3`
  font-size: 1rem;
  margin: 0 0 8px 0;
  ${media.medium`
    font-size: 1.5rem;
  `}
`;

export const MemberTitle = styled.p`
  margin: 0;
`;

export const TextWrap = styled(({ contained, ...rest }) => <Box {...rest} />)<TeamMemberProps>`
  padding: 0 ${SPACING.one}px;
  ${media.medium`
    padding: ${props => (!props.contained ? `0` : `0 ${SPACING.one}px`)};
  `}
`;

export const LinkText = styled.button<TeamMemberProps>`
  font-size: ${props => (props.contained ? `1.5rem` : `1rem`)};
  padding: 8px ${SPACING.one}px ${SPACING.one}px ${SPACING.one}px;
  margin: 0;
  line-height: 125%;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  font-family: ${FONTS.primary};

  :hover {
    text-decoration: underline;
  }
`;
