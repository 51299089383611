import { FluidObject } from 'gatsby-image';
import React, { ReactElement } from 'react';
import { Image } from '../../../components/atoms/';
import { Grid } from '../../../components/grid';
import {
  ContainedText,
  ImageOuter,
  LinkText,
  MemberName,
  MemberTitle,
  OverLay,
  StyledTeamMember,
  TextWrap,
} from './teamMember-styles';

interface TeamMemberProps {
  title: string;
  name: string;
  linkText?: string;
  imageData: FluidObject;
  onClick: () => void;
  onKeyPress: () => void;
  readonly contained: boolean;
}

interface TextProps {
  title: string;
  name: string;
  contained: boolean;
}

export type TeamMemberTypes = TeamMemberProps;

export const TeamMember = ({
  imageData,
  onClick,
  onKeyPress,
  name,
  title,
  contained,
  linkText = 'View Bio',
  ...props
}: TeamMemberProps): ReactElement => {
  return (
    <StyledTeamMember title={name} onKeyPress={onKeyPress} aria-label={`${name} ${title}`} {...props}>
      <Grid colspan={[1]} gutterY={['24px']}>
        <ImageOuter>
          <OverLay className="overlay" tabIndex={0}>
            <Image imageData={imageData} altText={`${name} ${title}`} />
          </OverLay>
          <ContainedText className="text">
            {contained && <StyledText contained={contained} title={title} name={name}></StyledText>}
            <LinkText onClick={onClick}>{linkText}</LinkText>
          </ContainedText>
        </ImageOuter>
        {!contained && <StyledText contained={contained} title={title} name={name}></StyledText>}
      </Grid>
    </StyledTeamMember>
  );
};

const StyledText = ({ name, title, contained }: TextProps): ReactElement => {
  return (
    <TextWrap contained={contained}>
      <MemberName>{name}</MemberName>
      <MemberTitle className="small">{title}</MemberTitle>
    </TextWrap>
  );
};
