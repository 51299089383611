import { responsiveFontSize } from '@cheese-fondue/styles/typography';
import { FluidObject } from 'gatsby-image';
import { nanoid } from 'nanoid';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { media } from '../../helpers/media-queries';
import { COLORS, SPACING } from '../../styles/theme-constants';
import { Image } from '../atoms';
import { Box, Grid } from '../grid';
import { AspectRatio } from '../grid/box';
import { ParallaxWrapper } from '../parallaxWrapper/parallaxWrapper';

export interface TeamHeaderHeroProps {
  backgroundImage: FluidObject;
  cta?: {
    text: string;
    url: string;
  };
  imageAltText: string;
  title?: string;
  subtitle?: string;
  rightText?: string;
  darkBackground?: boolean;
  parallax?: boolean;
}

const SquareWithHoleInTheMiddle = (): ReactElement => {
  const id = nanoid();
  return (
    <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" x="0" y="0" viewBox="0 0 100 100">
      <mask id={id}>
        <rect fill="white" width="300" height="300" x="-100" y="-100" />
        <circle cx="50" cy="50" r="50%" fill="black" />
      </mask>
      <rect mask={`url(#${id})`} fill="white" width="300" height="300" x="-100" y="-100" />
    </svg>
  );
};

const StyledBox = styled(Box)<{ dark: boolean }>`
  position: relative;
  overflow: hidden;
  color: ${props => (props.dark ? COLORS.orange100 : COLORS.blue400)};
`;

const Cutout = styled.div`
  position: absolute;
  top: 0;
  width: 100.01%; /* SAFARI FIX TO AVOID BACKGROUND BLEEDING OUTSIDE STENCIL */
  height: 100%;
  z-index: 1;
`;

const Title = styled.h1`
  color: ${props => props.color};
  margin-top: ${SPACING.four}px;
  margin-bottom: ${SPACING.two}px;
  margin-left: ${SPACING.one}px;
  width: 100%;

  ${responsiveFontSize(3.75, 9, -0.06, 95, 110)};

  ${media.medium`
    margin-top: ${SPACING.six}px;
    margin-bottom: ${SPACING.one}px;
    margin-left: ${SPACING.two}px;
  `}
`;

const LeftBox = styled(Box)`
  padding-left: ${SPACING.one}px;
  ${media.medium`
    padding-left: ${SPACING.two}px;
  `}
`;

const RightBox = styled(Box)`
  padding-left: ${SPACING.one}px;
  ${media.medium`
    padding-left: 0;
  `}
`;

const ContentGrid = styled(Grid)`
  z-index: 2;
`;

const StyledImage = styled.div`
  position: absolute !important;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  /* gatsby-image-wrapper */
  > div {
    width: 100%;
    height: 100%;
  }
`;

export const TeamHeaderHero = ({
  backgroundImage,
  title,
  imageAltText,
  subtitle,
  rightText,
  darkBackground = false,
  parallax = true,
  ...props
}: TeamHeaderHeroProps): ReactElement => {
  const lines = rightText ? rightText.split('\n') : [];

  return (
    <Grid {...props}>
      <StyledBox
        dark={+darkBackground}
        aspectRatio={[AspectRatio.Free, AspectRatio.Free, AspectRatio.OneByOne]}
        cols={[12]}
      >
        <Cutout>
          <SquareWithHoleInTheMiddle />
        </Cutout>
        {parallax ? (
          <ParallaxWrapper speed={-0.46}>
            <StyledImage>
              <Image imageData={backgroundImage} altText={imageAltText} />
            </StyledImage>
          </ParallaxWrapper>
        ) : (
          <StyledImage>
            <Image imageData={backgroundImage} altText={imageAltText} />
          </StyledImage>
        )}

        <ContentGrid>
          <Box cols={[10]}>{title && <Title>{title}</Title>}</Box>
          <LeftBox cols={[11, 4]}>{subtitle && <p className="medium">{subtitle}</p>}</LeftBox>
          <Box cols={[0, 3]}></Box>
          <RightBox cols={[11, 4]}>
            {lines.map(line => (
              <p key={line} className="medium">
                {line}
              </p>
            ))}
          </RightBox>
        </ContentGrid>
      </StyledBox>
    </Grid>
  );
};
