import React, { ReactElement } from 'react';
import { SPACING } from '../../../styles';
import { Text } from '../../atoms/text';
import { Box, Grid } from '../../grid';
import { TeamMember } from '../teamMember/teamMember';
import { SectionBody, Summary, SummaryItem, TeamListSection } from './teamList-styles';

interface TeamListProps {
  headline: string;
  summary: object;
  members: object;
  teamMemberClickHandler: () => null;
}

export type TeamListTypes = TeamListProps;

export const TeamList = ({
  headline,
  summary,
  members,
  teamMemberClickHandler,
  ...props
}: TeamListProps): ReactElement => {
  return (
    <TeamListSection data-testid="teamList-example" {...props}>
      <Grid
        colspan={12}
        css={[
          `padding: ${SPACING.three}px ${SPACING.one}px ${SPACING.one}px ${SPACING.one}px;`,
          `padding: ${SPACING.four}px 0 ${SPACING.two}px;`,
        ]}
      >
        <Box cols={[12, 9]}>
          <Text as="h2" size={['h2', 'h1']}>
            {headline}
          </Text>
        </Box>
        {summary && (
          <Box cols={[12, 3]}>
            <Summary>
              <SummaryItems bullets={summary} />
            </Summary>
          </Box>
        )}
      </Grid>
      <SectionBody
        as={Grid}
        colspan={[12]}
        gutterY={['24px']}
        gutterX={['0', SPACING.three + `px`, SPACING.four + `px`]}
      >
        <TeamMembers team={members} teamMemberClickHandler={teamMemberClickHandler} />
      </SectionBody>
    </TeamListSection>
  );
};

const SummaryItems = ({ bullets }): ReactElement => {
  const listItems = bullets.map((item, index) => (
    <SummaryItem key={index}>
      {item.area} ({item.count})
    </SummaryItem>
  ));
  return listItems;
};

const TeamMembers = ({ team, teamMemberClickHandler }): ReactElement => {
  const teamMembers = team.map(item => (
    <Box cols={[6, 3]} key={item.name}>
      <TeamMember
        name={item.name}
        title={item.title}
        imageData={item.imageData}
        onClick={() => teamMemberClickHandler(item)}
        onKeyPress={() => teamMemberClickHandler(item)}
        contained={false}
      />
    </Box>
  ));
  return teamMembers;
};
