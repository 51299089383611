import styled from 'styled-components';
import { media } from '../../../helpers';
import { SPACING } from '../../../styles';

export const TeamListSection = styled.div`
  ${media.medium`
    padding: 0 ${SPACING.two}px;
  `}
`;

export const SectionBody = styled.div``;

export const Summary = styled.ul`
  margin: ${SPACING.one}px 0 0 0;
  padding: 0;

  ${media.medium`
    text-align: right;
    margin:0;
  `}
`;

export const Headline = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
`;

export const SummaryItem = styled.li`
  list-style: none;
  text-transform: capitalize;
  ${media.medium`
    font-size: 1.5rem;
  `}
  line-height: 120%;
`;
